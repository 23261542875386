<template>
	<div id="app">
		<div class="toolbar">
			<BaseButton
				v-if="music_source"
				name="sound"
				icon="sound"
				is-black
				:is-active="!isMuted"
				@click="toggleAudio()"
			>
				{{ $l10n('sound') }}
			</BaseButton>
			<BaseButton
				v-if="!!aboutHtml"
				name="info"
				icon="help"
				is-black
				icon-with-text
				class="info-button"
				@click="showModal('about')"
			>
				{{ $l10n('info') }}
			</BaseButton>
		</div>
		<Transition name="fade" mode="out-in">
			<Component
				:is="screen.component"
				:key="currentScreen"
				v-bind="screen.props"
				@done="nextScreen"
			/>
		</Transition>
		<BaseModal v-if="!!aboutHtml" name="about" :content="aboutHtml" />
	</div>
</template>

<script>
import { shuffle } from 'lodash';
import { mapState, mapMutations } from 'vuex';
import { Howl } from 'howler';
import BaseButton from '@/components/BaseButton.vue';
import BaseModal from '@/components/BaseModal.vue';
import Home from '@/views/Home.vue';
import SlideCarousel from '@/components/SlideCarousel.vue';
import Datapoint from '@/views/Datapoint.vue';
import Interrupt from '@/views/Interrupt.vue';
import DemoSurvey from '@/views/DemoSurvey.vue';
import End from '@/views/End.vue';

function ensureArray(value) {
	if (value === null || typeof value === 'undefined') {
		return value;
	}

	if (!(value instanceof Array)) {
		return [value];
	}

	return value;
}

export default {
	components: {
		BaseButton,
		BaseModal,
	},
	inheritAttrs: false,
	props: {
		introHtml: {
			type: String,
			required: true,
		},
		aboutHtml: {
			type: String,
			default: null,
		},
		outroHtml: {
			type: String,
			required: true,
		},
		summaryHtml: {
			type: String,
			default: null,
		},
		introSlides: {
			type: Array,
			default: null,
		},
		datapointQuestions: {
			type: Array,
			required: true,
		},
		outcomeQuestions: {
			type: Array,
			default: null,
		},
		interruptQuestions: {
			type: Array,
			default: null,
		},
		demographicSurvey: {
			type: Object,
			default: null,
		},
		options: {
			type: Object,
			default: null,
		},
		music_source: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			currentScreen: 0,
		};
	},
	computed: {
		screens() {
			const home = {
				component: Home,
				props: { content: this.introHtml },
			};

			const end = {
				component: End,
				props: {
					content: this.outroHtml,
					summary: this.summaryHtml,
					datapoints: this.datapointQuestions,
				},
			};

			let intro = null;
			if (this.introSlides && this.introSlides.length > 0) {
				intro = {
					component: SlideCarousel,
					props: {
						slides: this.introSlides,
						name: 'intro',
						class: 'intro',
					},
				};
			}
			// comment
			let pregameQuestions = [];
			let postgameQuestions = [];
			if (
				this.options.outcome &&
				this.outcomeQuestions &&
				this.outcomeQuestions.length > 0
			) {
				let questions = this.outcomeQuestions.map(question => ({
					component: Interrupt,
					props: {
						...question,
						type: 'outcome',
					},
				}));
				if (this.options['shuffle-outcomes']) {
					questions = shuffle(questions);
				}

				questions.forEach((question, index) => {
					question.props.placement = {
						current: index + 1,
						total: questions.length,
					};
				});

				const placement = ensureArray(this.options.outcome);
				if (placement.includes('before')) {
					pregameQuestions = [...questions];
				}
				if (placement.includes('after')) {
					postgameQuestions = [...questions];
				}
			}

			let gameQuestions = [];
			if (this.datapointQuestions && this.datapointQuestions.length > 0) {
				let datapoints = [...this.datapointQuestions];
				if (this.options['shuffle-datapoints']) {
					datapoints = shuffle(datapoints);
				}

				let interrupts = [...this.interruptQuestions];
				if (this.options['shuffle-interrupts']) {
					interrupts = shuffle(interrupts);
				}

				// Get next question in set, looping around as needed
				let pointer = 0;
				const nextInterrupt = () => {
					if (pointer >= interrupts.length - 1) {
						pointer = 0;
					}
					return interrupts[pointer++];
				};

				const placement = ensureArray(this.options['interrupts']);
				datapoints.forEach((datapoint, index) => {
					gameQuestions.push({
						component: Datapoint,
						props: {
							...datapoint,
							number: index + 1,
						},
					});

					// Add interrupt after every datapoint, or at specific positions
					if (!placement || placement.includes(index + 1)) {
						const interrupt = nextInterrupt();
						gameQuestions.push({
							component: Interrupt,
							props: {
								...interrupt,
								name: `${interrupt.name}|${datapoint.name}`,
								type: 'followup',
							},
						});
					}
				});
			}

			let survey = null;
			if (this.demographicSurvey) {
				survey = {
					component: DemoSurvey,
					props: this.demographicSurvey,
				};
			}

			return [
				home,
				intro,
				...pregameQuestions,
				...gameQuestions,
				...postgameQuestions,
				survey,
				end,
			].filter(v => v);
		},
		screen() {
			return this.screens[this.currentScreen];
		},
		...mapState('audio', {
			isMuted: 'muted',
		}),
	},
	watch: {
		isMuted(muted) {
			if (this.music) {
				if (!muted && !this.music.playing()) {
					this.music.play();
				}

				this.music.mute(muted);
			}
		},
	},
	created() {
		if (this.music_source) {
			this.music = new Howl({
				src: [this.music_source],
				html5: true,
				loop: true,
			});
		}
	},
	methods: {
		reset() {
			this.currentScreen = 0;
		},
		nextScreen() {
			if (this.currentScreen >= this.screens.length - 1) {
				return this.reset();
			}

			this.currentScreen++;
		},
		...mapMutations('audio', {
			toggleAudio: 'toggle',
		}),
	},
	provide() {
		return {
			parentView: 'app',
		};
	},
};
</script>

<style lang="scss">
#app {
	width: 100%;
	height: 100%;
}
.toolbar {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	padding: calc(#{$screen-padding} / 2);
	z-index: 3;
	pointer-events: none;

	.button {
		margin: 0;
		pointer-events: all;
	}

	.button + .button {
		margin-left: rem(9);
	}
}

.button.info-button .button__inner {
	background: $color-purple;
	color: $color-black;
}
</style>
